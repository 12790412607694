
/* HELPER STYLES */
.BG_WHITE { background-color:#FFFFFF; }

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}
.clearfix-right:after {
  content: "";
  display: table;
  clear: right;
}
.clearfix-left:after {
  content: "";
  display: table;
  clear: left;
}

.LEFT { float:left; }
.RIGHT { float:right; }

.COPY_CENTER { text-align:center !important; }
.COPY_LEFT { text-align:left !important; }
.COPY_RIGHT { text-align:right !important; }
.COPY_UPPER { text-transform:uppercase; }

.FONT-HELVETICA { font-family:Helvetica; }

.NO_PADDING { padding:0 0 0 0; }
.NO_PADDING_BOTTOM { padding-bottom:0px !important; }
.NO_PADDING_TOP { padding-top:0px !important; }

.NO_PADDING_LEFT { padding-left:0px !important; }
.NO_PADDING_RIGHT { padding-right:0px !important; }

.FULL_WIDTH { width:100%; }

.REL_POS_CONT { position: relative; }

.DISPLAY_BLOCK {
	display:block;
}

.DESKTOP {
	@include breakWidth(800) {
		display:none;
	}
}
.MOBILE {
	display:none;
	@include breakWidth(800) {
		display:inherit;
	}
}
