@font-face {
    font-family: 'Bauhaus-Std';
    src: url('../fonts/BauhausStd-Bold.eot');
    src: local('Bauhaus Std Bold'), local('BauhausStd-Bold'),
        url('../fonts/BauhausStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/BauhausStd-Bold.woff') format('woff'),
        url('../fonts/BauhausStd-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

.webfont_mainfont_style {
    font-family: 'Bauhaus-Std' !important;
}

/* Font downloaded from FontsForWeb.com */

@import '../variables';
/*@import 'fonts';*/
@import '../mixins';

.FS {
    height: 100%;
    @include breakWidth(800) {
        height:auto;
    }
}
body {
    background-color:$WHITE;
}
button {
    border:none;
    outline: 0;
    outline:none;
    -webkit-appearance: none;
    -moz-appearance:none;
}
h1 {
    font-family:'Bauhaus-Std', 'Calibri', Calibri, Arial, Helvetica;
    font-size:45px;
    color:#626366;
    font-weight:normal;
    @include breakHeight(600) {
        font-size:32px;
    }
    @include breakWidth(800) {
        font-size:35px;
    }
}
h2 {
    font-family:Arial,Calibri,Helvetica;
    font-size:18px;
    font-weight:bold;
    @include breakWidth(800) {
        font-size:16px;
    }
}
.loadArea {
    position:relative;
    width:100%;
    height:100%;
    overflow:hidden;
    @include breakWidth(800) {
        height:auto;
    }
}
#loadarea-product-slider.loadArea {
    height:100%;
    @include breakWidth(800) {
        height:100%;
    }
}
.page_container {
    position:relative;
    width:100%;
    box-sizing: border-box;
    background-image:none;
    background-size:cover;
    background-attachment: fixed;
    background-repeat:no-repeat;
    /*background-blend-mode: multiply;*/
    background-position:center center;
    overflow:hidden;
    @include breakWidth(800) {
        border-width:0 0 15px 0;
    }
    &.is-os {
      background-attachment: initial !important;
      background-size:cover;
    }
    .fullscreen-bg {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
    }
    .main_container {
        position:relative;
        width:800px;
        margin-left:-400px;
        left:50%;
        top:50%; /* temporary solution for now until javascript reposition vertical center function has been created */
        
        @include breakWidth(1700) {
            width:800px;
            margin-left:-400px;
        }
        @include breakWidth(1300) {
            width:800px;
            margin-left:-400px;
        }
        @include breakWidth(800) {
            width:700px;
            margin-left:-350px;
            top:30px;
            margin-top:0px;
            margin-bottom:30px;
        }
        @include breakWidth(740) {
            width:400px;
            margin-left:-200px;
            top:30px;
            margin-top:0px;
            margin-bottom:30px;
        }
        @include breakWidth(400) {
            width:300px;
            margin-left:-150px;
        }
        h1, h2, p {
            color:$WHITE;
            margin:0 0 0 0;
            padding:0 0 0 0;
            line-height:110%;
            line-height:1.1;
        }
        header {
            padding-bottom:25px;
            h2 {
                padding-top:5px;
            }
        }
        p {
            font-family:Arial,Calibri,Helvetica;
            font-weight:normal;
            font-size:15px;
            line-height:140%;
            line-height:1.4;
            @include breakWidth(800) {
                font-size:13px;
            }
            @include breakHeight(600) {
                font-size:12px;
                line-height:120%;
                line-height:1.2;
            }
        }

        .options-container {
            position:relative;
            padding-top:25px;
            .left-container, .right-container {
                width:50%;
                position:relative;
                display:block;
                min-height:240px;
                box-sizing: border-box;
                @include breakHeight(740) {
                    min-height:200px;
                    height:200px;
                }
                @include breakWidth(800) {
                    min-height:0;
                    height:auto;
                    clear:both;
                }
                .button-bottom-holder {
                    position:absolute;
                    top:20px;
                    left:0;
                    @include breakWidth(800) {
                        position:relative;
                        top:auto;
                    }
                }
            }
            .left-container {
                width:55%;
                float:left;
                perspective: 800px;
                position:relative;
                /*border:1px solid $RED;*/
                @include breakWidth(800) {
                    width:100%;
                    perspective:none;
                }
                img {
                    width:390px;
                    height:219px;

                    @include breakWidth(800) {
                        display:none;
                    }
                }
                .prod-image-flip {
                    width:390px;
                    height:219px;
                    position: absolute;
                    /*transform-style: preserve-3d;*/
                    /*transition: transform 1s;*/
                    /*transform-origin: right center;*/
                    @include breakWidth(800) {
                        display:none;
                    }
                }
                    img {
                        margin: 0;
                        display: block;
                        position: absolute;
                        backface-visibility: hidden;
                        box-sizing: border-box;
                        /*border:1px solid yellow;*/
                        @include breakWidth(800) {
                            display:none;
                            height:auto;
                        }
                    }
            }
            .right-container {
                width:40%;
                float:left;
                /*border:1px solid black;*/
                @include breakHeight(740) {
                    float:left;
                }
                @include breakWidth(800) {
                    width:100%;
                    float:left;
                }

            }
        }

        button{
            background-color:rgba(0, 0, 0, 0.5);
            padding:15px;
            font-family:Arial,Helvetica;
            font-size:16px;
            color:$WHITE;
            font-weight:bold;
            display:block;
            margin-bottom:10px;
            @include breakWidth(800) {
                font-size:13px;
                width:100%;
                box-sizing: border-box;
                padding:10px;
            }
        }
    }
}

button {
    #prod-aquamarine-aq10 &{
        &:hover {
            background-color: darken( $AQUAMARINE, 20% );
        }
    }
    #prod-emerald-e12 &{
        &:hover {
            background-color: darken( $EMERALD, 20% );
        }
    }
    #prod-topaz-t35 &{
        &:hover {
            background-color: darken( $TOPAZ, 20% );
        }
    }
    #prod-granite-g60 &{
        &:hover {
            background-color: darken( $GRANITE, 20% );
        }
    }
    #contact-page &{
        &:hover {
            background-color: darken( #d3d5d6, 20% );
        }
    }
}

#slider-page {
    background-color:$WHITE;
    background-image:url(../../images/_slider-backgrounds/prod-aquamarine-aq10.jpg);
    background-size:auto 100%;
    background-position:right center;
    @include breakWidth(800) {
        background-image:none;
    }
}
#about-nutec {
    background-image:url(../../images/about-nutec.jpg);
    background-color:#d3d5d6;
    @include breakWidth(800) {
        background-image:none;
    }
    h1, h2, p {
        color:#626366;
    }
    .main_container {
        padding-top:35px;
        padding-bottom:35px;
    }
}
#prod-aquamarine-aq10 {
    background-image:url(../../images/prod-aquamarine-aq10.jpg);
    background-color:$AQUAMARINE;
    @include breakWidth(800) {
        background-image:none;
    }
}
#prod-emerald-e12 {
    background-image:url(../../images/prod-emerald-e12.jpg);
    background-color:$EMERALD;
    @include breakWidth(800) {
        background-image:none;
    }
}
#prod-topaz-t35 {
    background-image:url(../../images/prod-topaz-t35.jpg);
    background-color:$TOPAZ;
    @include breakWidth(800) {
        background-image:none;
    }
}
#prod-granite-g60 {
    background-image:url(../../images/prod-granite-g60.jpg);
    background-color:$GRANITE;
    @include breakWidth(800) {
        background-image:none;
    }
}
#contact-page {
    background-image:url(../../images/contact-page.jpg);
    background-color:#d3d5d6;
    @include breakWidth(800) {
        background-image:none;
    }
    h1, h2, p {
        color:#626366;
    }
}



/*
*
* LIGHTBOX STUFFS
*/
.lightbox-mask {
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.7);
    display:none;
    z-index: 500;
}
.page-lightbox {
    position:absolute;
    width:900px;
    height:500px;
    left:50%;
    top:50%;
    margin-left:-450px;
    margin-top:-250px;
    display:none;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:right center;
    z-index: 510;
    @include breakWidth(800) {
        width:100%;
        left:0;
        margin-left:0;
        top:0;
        margin-top:0;
        height:100%;
    }
    .close-lightbox-x {
        position:absolute;
        top:0;
        right:0;
        color:$WHITE;
        font-weight:bold;
        font-family:Arial,Helvetica,Calibri;
        font-size:15px;
        cursor:pointer;
        padding:15px;
    }
    .navbar {
        float:left;
        position:relative;
        height:100%;
        width:80px;
        @include breakWidth(800) {
            display:none;
        }
        .nav-cont {
            position:absolute;
            top:50%;
            left:50%;
            margin-top:-25px;
            margin-left:-5px;
            .nav-bullet {
                background-color:$WHITE;
                margin-bottom:10px;
                width:10px;
                height:10px;
                border-radius:10px;
                cursor:pointer;
            }
        }
        #prod-aquamarine-aq10 &{
            background-color:$AQUAMARINE;
        }
        #prod-emerald-e12 &{
            background-color:$EMERALD;
        }
        #prod-topaz-t35 &{
            background-color:$TOPAZ;
        }
        #prod-granite-g60 &{
            background-color:$GRANITE;
        }
    }
    .content-area {
        overflow:hidden;
        .prod-content {
            position:absolute;
            width:100%;
            height:auto;
            left:0;
            top:50%;
            box-sizing: border-box;
            padding-left:130px;
            border:0px solid red;
            @include breakWidth(800) {
                padding-left:20px;
            }
            &.tech-specs {
                display:none;
            }
            &.features {
                display:none;   
            }
            &.recommended {
                
            }
        }
        h2 {
            font-family:'Bauhaus-Std', 'Calibri', Calibri, Arial, Helvetica;
            font-size:40px;
            font-weight:normal;
            #prod-aquamarine-aq10 &{
                color:$AQUAMARINE;
            }
            #prod-emerald-e12 &{
                color:$EMERALD;
            }
            #prod-topaz-t35 &{
                color:$TOPAZ;
            } 
            #prod-granite-g60 &{
                color:$GRANITE;
            }
            @include breakWidth(800) {
                font-size:35px;
            }
        }
        .download-btn {
            background-color:rgba(0, 0, 0, 0.5);
            padding:15px;
            font-family:Arial,Helvetica;
            font-size:15px;
            color:$WHITE;
            font-weight:bold;
            text-decoration:none;
            margin-bottom:10px;
            float:left;
            margin-top:10px;
            margin-right:10px;
            text-transform: uppercase;
            &.switch-now-btn {
                cursor:pointer;
            }
            @include breakWidth(800) {
                width:85%;
                padding:10px;
            }
        }
        p, li {
            font-size:15px;
            color:$WHITE;
            text-align:left;
            line-height:120%;
            line-height:1.2;
            font-weight:normal;
            @include breakWidth(800) {
                font-size:12px;
                line-height:1;
            }
        }
        ul {
            margin:10px 0 0 20px;
            padding: 0 0 0 0;
            li {
                padding:0 0 0 20px;
                margin: 0 0 0 0;
                line-height:160%;
                line-height:1.6;
                font-weight:normal;
                @include breakWidth(800) {
                    line-height:110%;
                    line-height:1.1;
                    padding-right:20px;
                }
            }
        }
        .colorme {
            font-weight:bold;
            #prod-aquamarine-aq10 &{
                color:$AQUAMARINE;
                font-weight:bold;
            }
            #prod-emerald-e12 &{
                color:$EMERALD;
                font-weight:bold;
            } 
            #prod-topaz-t35 &{
                color:$TOPAZ;
                font-weight:bold;
            }
            #prod-granite-g60 &{
                color:$GRANITE;
                font-weight:bold;
            }
        }
    }
    #prod-aquamarine-aq10 &{
        background-color: darken($AQUAMARINE, 10%);
        background-image:url(../../images/aquamarine-lightbox.png);
        @include breakWidth(800) {
            background-image:none;
        }
    }
    #prod-emerald-e12 &{
        background-color: darken($EMERALD, 10%);
        background-image:url(../../images/emerald-lightbox.png);
        @include breakWidth(800) {
            background-image:none;
        }
    }
    #prod-topaz-t35 &{
        background-color: darken($TOPAZ, 10%);
        background-image:url(../../images/topaz-lightbox.png);
        @include breakWidth(800) {
            background-image:none;
        }
    }
    #prod-granite-g60 &{
        background-color: darken($GRANITE, 10%);
        background-image:url(../../images/granite-lightbox.png);
        @include breakWidth(800) {
            background-image:none;
        }
    }
}


/*
* CONTACT US FORM STYLES
*/
#technical-support {
    padding-top:100px;
    padding-bottom:60px;
    @include breakWidth(800) {
        padding-top:35px;
    }
    .options-cont {
        position:relative;
        border:0px solid $RED;
        height:150px;
        @include breakWidth(800) {
            height:100px;
        }
        .btn-cont {
            position:relative;
            width:33.3333%;
            float:left;
            background-position:center top;
            background-repeat:no-repeat;
            background-size:110px 110px;
            height:140px;
            p {
                position:absolute;
                bottom:0;
                left:0;
                box-sizing: border-box;
                width:100%;
                text-align:center;
                font-size:16px;
                font-weight:bold;
                @include breakWidth(800) {
                    font-size:11px;
                }
            }
            &.tech-support {
                background-image:url(../../images/svg/icon_technical-support.svg);
            }
            &.spare-parts {
                background-image:url(../../images/svg/icon_spare-parts.svg);
            }
            &.free-advice {
                background-image:url(../../images/svg/icon_free-advice.svg);
            }
            @include breakWidth(800) {
                height:100px;
                background-size:70px 70px;
            }
        }
    }
    .contact-p {
        font-weight:bold;
        text-align:center;
        padding-top:15px;
        a {
            color:$NUTECGREEN;
            text-decoration:none;
        }
    }
}
#contact-us {
    input[type=text], input[type=email], textarea {
        padding:10px;
        box-sizing: border-box;
        color:#000000;
        margin-bottom:10px;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: $PLACEHOLDER;
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: $PLACEHOLDER;
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: $PLACEHOLDER;
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: $PLACEHOLDER;
        }

        &.left-input, &.right-input {
            width:55%;
            @include breakWidth(800) {
                width:100%;
                box-sizing: border-box;
            }
        }
        &.left-input {
            float:left;
        }
        &.right-input {
            width:43%;
            float:right;
            @include breakWidth(800) {
                width:100%;
            }
        }
        &.error {
            border:1px solid $RED;
        }
        &.success {
            border:1px solid $NUTECGREEN;
        }
    }
    textarea {
        width:100%;
        min-height:250px;
        max-height:250px;
        height:250px;
    }
    form button {
        float:right;
        padding-left:25px;
        padding-right:25px;
    }
}

#contact-addresses {
    .address-container {
        margin-top:50px;
        position:relative;
        display:block;
        p {
            font-size:15px;
            color:$BLACK;
            text-align:left;
            font-weight:normal;
            line-height:130%;
            line-height:1.3;
            @include breakWidth(800) {
                font-size:13px;
            }
        }
        .address-col {
            float:left;
            box-sizing: border-box;
            &.col1 {
                margin-top:-7px;
                width:40%;
                p:first-child {
                    color:$NUTECGREEN;
                    padding-bottom:10px;
                    font-weight:bold;
                }
                a {
                    color:$BLACK;
                    text-decoration:none;
                }
                @include breakWidth(800) {
                    width:75%;
                }
            }
            &.col2 {
                float:right;
                width:110px;
                height:110px;
                margin-right:25px;
                @include breakWidth(800) {
                    width:20%;
                    margin-right:0;
                }
            }
            &.col3 {
                float:right;
                width:30%;
                @include breakWidth(800) {
                    width:75%;
                    float:left;
                }
            }
        }
    }
}



footer {
    .main-container {
        position:relative;
        width:980px;
        height:100%;
        box-sizing: border-box;
        left:50%;
        margin-left:-490px;
        padding-top:120px;
        padding-bottom:80px;
        @include breakWidth(1280) {
            width:800px;
            margin-left:-400px;
            padding-top:80px;
            margin-bottom:50px;
        }
        @include breakWidth(800) {
            width:700px;
            margin-left:-350px;
            padding-top:80px;
            margin-bottom:50px;
        }
        @include breakWidth(740) {
            width:400px;
            margin-left:-200px;
            padding-top:50px;
            margin-bottom:30px;
        }
        @include breakWidth(400) {
            width:300px;
            margin-left:-150px;
            padding-top:50px;
            margin-bottom:30px;
        }
    }
}




/*
*
*   full page slider
*/
.owl-slide {
    cursor:pointer;
    .content-cont {
        position:absolute;
        top:20%;
        left:30%;
        pointer-events: none;
        @include breakWidth(1280) {
            left:15%;
        }
        @include breakWidth(800) {
            top:100px;
            width:80%;
            left:10%;
            br {
                display:none;
            }
        }
        p {
            text-align:left;
            line-height:140%;
            line-height:1.4;
            font-size:18px;
            color:#626366;
            font-weight:normal;
            @include breakWidth(1800) {
                font-size:14px;
            }
            @include breakWidth(800) {
                color:$WHITE;
                text-shadow: 0px 0px 5px rgba(0, 0, 0, 1);
                .amplify {
                    color:$WHITE;
                }
            }
            .amplify {
                font-size:22px;
                display:inline-block;
                margin-top:-8px;
                padding-top:3px;
                font-weight:bold;
                @include breakWidth(1800) {
                    font-size:21px;
                }
            }
        }
        &.aquamarine {
            @include breakWidth(800) {
                left:10%;
                top:100px;
            }
            .amplify {
                color:$AQUAMARINE;
                @include breakWidth(800) {
                    color:$WHITE;
                }
            }
        }
        &.emerald {
            top:40%;
            left:20%;
            @include breakWidth(800) {
                left:10%;
                top:100px;
            }
            .amplify {
                color:$EMERALD;
                @include breakWidth(800) {
                    color:$WHITE;
                }
            }
        }
        &.topaz {
            @include breakWidth(800) {
                left:10%;
                top:100px;
            }
            .amplify {
                color:$TOPAZ;
                @include breakWidth(800) {
                    color:$WHITE;
                }
            }
        }
        &.granite {
            top:40%;
            left:20%;
            @include breakWidth(800) {
                left:10%;
                top:100px;
            }
            .amplify {
                color:$GRANITE;
                @include breakWidth(800) {
                    color:$WHITE;
                }
            }
        }
    }
}
.owl-carousel {
    position: relative;
    height: 100%;
    
    div:not(.owl-controls) {
        height: 100%;
    }
    
    .owl-slide {
        background-color:#FFFFFF;
        background-image:none;
        background-size:auto 100%;
        background-position:right center;
        background-repeat:no-repeat;
        @include breakWidth(800) {
            background-size:100% 100%;
        }
        &.aquamarine {
            background-image:url(../../images/_slider-backgrounds/prod-aquamarine-aq10.jpg);
            @include breakWidth(800) {
                /*background-image:none;*/
                background-image:url(../../images/_slider-backgrounds/mobile-prod-aquamarine.jpg);
            }
        }
        &.emerald {
            background-image:url(../../images/_slider-backgrounds/prod-emerald-e12.jpg);
            @include breakWidth(800) {
                /*background-image:none;*/
                background-image:url(../../images/_slider-backgrounds/mobile-prod-emerald.jpg);
            }
        }
        &.topaz {
            background-image:url(../../images/_slider-backgrounds/prod-topaz-t35.jpg);
            @include breakWidth(800) {
                /*background-image:none;*/
                background-image:url(../../images/_slider-backgrounds/mobile-prod-topaz.jpg);
            }
        }
        &.granite {
            background-image:url(../../images/_slider-backgrounds/prod-granite-g60.jpg);
            @include breakWidth(800) {
                /*background-image:none;*/
                background-image:url(../../images/_slider-backgrounds/mobile-prod-granite.jpg);
            }
        }
        @include breakWidth(800) {
            height:100%;
            background-image:none;
        }
        .slider-label {
            position:absolute;
            top:80px;
            right:25%;
            width:185px;
            height:666px;
            @include breakHeight(740) {
                width:100px;
                height:360px;
            }
            @include breakWidth(1600) {
                width:120px;
                height:432px;
            }
            @include breakWidth(1280) {
                width:100px;
                height:360px;
            }
            @include breakWidth(800) {
                display:none;
            }
            &.mobile-label {
                display:none;
                @include breakWidth(800) {
                    width:300px;
                    height:80px;
                    display:block;
                    right:0px;
                    bottom:80px;

                    top:auto;
                }
            }
        }
    }
    
    div.owl--text {
        position: absolute;
        bottom: 4em;
        left: 2em;
        width: 20em;
        height: 8em;
        padding: 1em;
        background: rgba(255, 255, 255, .5);
        border-radius: 4px;
    }
    .owl-dots {
        display:block;
        background-color:transparent;
        width:100%;
        bottom:60px;
        top:auto;
        left:0;
        right:auto;
        height:50px !important;
        position:absolute;
        width:140px;
        left:50%;
        margin-left:-70px;
        @include breakWidth(800) {
            bottom:20px;
            height:20px;
        }
        .owl-dot {
            background-color:#afafaf;
            width:20px;
            height:20px;
            border-radius:20px;
            position:relative;
            float:left;
            margin-left:10px;
        }
    }
    
   
}

/*
*
*/
.loading-screen {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(255,255,255,1);
    z-index: 9000;
    svg path, svg rect{
        fill: $NUTECGREEN;
    }
    .loader--style8 {
        position:absolute;
        left:50%;
        top:50%;

        width:48px;
        height:60px;
        margin-left:-24px;
        margin-top:-30px;
    }

}



/*
*
*/
nav.side-nav-cont {
    position:fixed;
    z-index: 400;
    height:350px;
    width:200px;
    left:10%;
    top:50%;
    margin-top:-175px;
    /*pointer-events: none;*/
    @include breakWidth(1600) {
        margin-top:-100px;
        left:3%;
    }
    @include breakWidth(800) {
        display:none;
    }
    ul {
        margin:50px 0 0 0;
        padding:0 0 0 0;
        list-style: none;
        li {
            margin:0 0 0 0;
            padding:0 0 8px 0;
            text-align:left;
            font-family:Arial,Helvetica,Calibri;
            font-size:12px;
            color:#626366;
            cursor:pointer;
        }
        &.color-white { 
            li {
                color:$WHITE;
            }
        }
    }
    .bar-container {
        position:relative;
        display:block;
        float:left;
        width:20px;
        height:350px;
        pointer-events: none;
        margin-right:15px;
        p {
            position:relative;
            float:left;
            transform: rotate(270deg);
            transform-origin: left top 0;
            width:80px;
            text-align:left;
            font-family:Arial,Helvetica,Calibri;
            font-size:11px;
            letter-spacing: 2px;
            color:#626366;
            &.color-white { 
                
                color:$WHITE;
                
            }
        }
        .bar {
            position:absolute;
            left:0;
            top:0%;
            height:200px;
            width:2px;
            margin-top:25px;
            margin-left:5px;
            background-color:#bcbec0;
            display:block;
            pointer-events: none;
            .progress-bar {
                position:relative;
                width:100%;
                height:50%;
                background-color:#626366;
                pointer-events: none;
                &.color-white {
                    background-color:$WHITE;
                }
            }
        }
    }
}

#topdiv {
    position:absolute;
    pointer-events: none;
    top:150px;
    left:0;
    
}
#contact-slide-trigger {
    position:relative;
    pointer-events: none;
    top:0;
    left:0;
}

@import '../plugins/owl.carousel.min.css';
@import '../helpers';